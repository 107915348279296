import '../polyfills';
import React, { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
// import App from "next/app";
import NProgress from 'nprogress'; // nprogress module
import dayjs from 'dayjs';
import Title from '@/components/Title';
import { GetLayout, LayoutAppProps, MyAppInitProps } from '@/typgins';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import { ConfigProvider, Modal } from 'antd';
import CommonConfigProvider from 'antd/lib/config-provider';
import zhCN from 'antd/lib/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import 'nprogress/nprogress.css'; // styles of nprogress
import { LayoutContext } from '@/components/Layout/context';
import '../styles/global.scss';
import App, {
  AppContext,
  AppInitialProps,
  NextWebVitalsMetric,
} from 'next/app';
import { extend } from '@/utils/request';
import { clearToken, getToken } from '@/utils/authority';
import Cookies from 'js-cookie';
import {
  destroyCookie,
  getRedirectUrl,
  getTokenServerSide,
  getUserInfoServerSide,
  setCookie,
  join,
} from '@/utils/utils';
import Upload from '@/components/Upload';
import { getFileInfo, uploadFile } from '@/service/file';
import PictureView from '@/components/PictureView';
import { getCurUser } from '@/service/user';
import useSWR from 'swr';
import usePureFetch from '@/hooks/usePureFetch';
import { apiPrefix } from '#/projectConfig';

const SUPPORT_PREVIEW_FILES = new Set([
  'xls',
  'xlsx',
  'xlsm',
  'ppt',
  'pptx',
  'doc',
  'docx',
]);
dayjs.extend(isLeapYear); // 使用插件
dayjs.locale('zh-cn'); // 使用本地化语言

if (!global.navigator) {
  global.navigator = { userAgent: 'chrome' } as any;
}

if (zhCN.Modal) {
  zhCN.Modal.justOkText = '确定';
}
Modal.defaultProps = {
  ...Modal.defaultProps,
  centered: true,
};

extend({
  noPermission() {
    if (process.browser) {
      clearToken();
      Cookies.remove('userInfo');
      window.location.href = getRedirectUrl(window.location.href);
    }
  },
});

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const getDefaultLayout: GetLayout = page => {
  return page;
};
Upload.defaultProps = {
  ...Upload.defaultProps,

  onUpload: uploadFile,
  onPreview: file => {
    if (/image\/.*/.test(file.type)) {
      if (file.originFileObj) {
        const reader = new FileReader();
        reader.onload = e => {
          PictureView({
            src: e.target?.result as string,
          });
        };
        reader.readAsDataURL(file.originFileObj);
      } else {
        PictureView({
          src: file.thumbUrl || file.url,
        });
      }
    } else if (file.originFileObj) {
      Modal.error({
        content: '暂未上传,上传后可查看',
      });
    } else if (file.type === 'application/pdf') {
      window.open(
        join(
          apiPrefix,
          `/file-online-preview/pdfjs/web/viewer.html?file=${encodeURIComponent(
            join(apiPrefix, `/file/previewFileByUri?uri=${file.uid}`),
          )}`,
        ),
      );
    } else if (SUPPORT_PREVIEW_FILES.has(file.name.split('.').pop() || '')) {
      window.open(
        join(apiPrefix, `/file-online-preview/onlinePreview?uri=${file.uid}`),
      );
    } else {
      window.open(file.thumbUrl || file.url);
    }
  },
  getFile: getFileInfo,
  onDownload: file => {
    window.open(file.thumbUrl || file.url);
  },
};
function MyApp(props: LayoutAppProps) {
  const { Component, pageProps, breadcrumbs, links } = props;
  const getLayout = Component.getLayout || getDefaultLayout;
  const router = useRouter();
  const token = getToken();

  const { data: userInfo } = useSWR(
    token ? ['user', token] : null,
    usePureFetch(getCurUser, true),
    {
      revalidateOnFocus: true,
    },
  );
  useEffect(() => {
    if (process.browser) {
      if (userInfo?.userId) {
        // eslint-disable-next-line
        (window as any)?._paq?.push(['setUserId', userInfo?.userId]);
      }
      // eslint-disable-next-line
      (window as any)?._paq?.push(['setCustomUrl', router.asPath]);
      // eslint-disable-next-line
      (window as any)?._paq?.push(['setDocumentTitle', document.title]);
      // eslint-disable-next-line
      (window as any)?._paq?.push(['trackPageView']);
      // eslint-disable-next-line
      (window as any)?._paq?.push(['setReferrerUrl', router.asPath]);
    }
  }, [router.asPath]);
  return (
    <>
      <Title />
      <CommonConfigProvider locale={zhCN}>
        <ConfigProvider locale={zhCN}>
          <LayoutContext.Provider
            value={{
              links,
              breadcrumbs,
              userInfo,
            }}
          >
            {getLayout(<Component {...pageProps} />, props)}
          </LayoutContext.Provider>
        </ConfigProvider>
      </CommonConfigProvider>
    </>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  (window as any)?.gtag?.('event', metric.name, {
    event_category:
      metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(
      metric.name === 'CLS' ? metric.value * 1000 : metric.value,
    ), // values must be integers
    event_label: metric.id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
MyApp.getInitialProps = async (
  appContext: AppContext,
): Promise<AppInitialProps & MyAppInitProps> => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  let userInfo = getUserInfoServerSide(appContext.ctx) || null;
  const cookieToken = getTokenServerSide(appContext.ctx);
  const token =
    (appContext.ctx.query.token as string | undefined) || cookieToken;
  if (appContext.ctx.query.token) {
    if (appContext.ctx.query.token !== cookieToken) {
      destroyCookie(appContext.ctx, 'token');
      destroyCookie(appContext.ctx, 'userInfo');
      userInfo = null;
      setCookie(appContext.ctx, 'token', token as string);
    }
  }
  if (!userInfo && token) {
    const userRes = await getCurUser(token);
    if (userRes.isSuccess) {
      userInfo = userRes.data || null;
    } else {
      console.error('token 过期');
      destroyCookie(appContext.ctx, 'token');
      destroyCookie(appContext.ctx, 'userInfo');
      if (appContext.ctx.req?.headers.cookie) {
        // eslint-disable-next-line no-param-reassign
        appContext.ctx.req.headers.cookie = appContext.ctx.req.headers.cookie.replace(
          new RegExp(`${token}=[^;]*; `, 'i'),
          '',
        );
      }
    }
    if (userInfo) {
      destroyCookie(appContext.ctx, 'userInfo');
      setCookie(
        appContext.ctx,
        'userInfo',
        JSON.stringify({
          userId: userInfo?.userId,
          userAccount: userInfo?.userAccount,
          roleCode: userInfo?.roleCode,
        }),
      );
    }
  }

  return {
    ...appProps,
    breadcrumbs: [],
    links: [],
  };
};

export default MyApp;
